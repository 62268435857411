/**
 * User
 */
import { Model } from "./model";
import { PaymentProvider } from "./payments";

export enum UserRoles {
  admin = "admin",
  user = "user",
  customer = "customer",
}

export enum BillingType {
  area = "AREA",
  credits = "CREDITS",
  none = "NONE",
}

const prefix = "https://mapflow.ai" as const;

export const ROLES_KEY = `${prefix}/roles` as const;
export const LOGINS_COUNT = `${prefix}/loginscount` as const;

export type PriceItem = { amount: number; price: number };

export type UserInfo = {
  balance: number;
  priceList: PriceItem[];
};

export type TMUserProfile = {
  firstName: string;
  lastName: string;
  // additional fields
  country?: string;
  company?: string;
  job_profile?: string;
  role?: string;
  education?: string;
  gis_exp?: string;
  applications?: string;
};

export interface IUserAPI {
  getUserInfo(): Promise<UserInfo>;
}

export type TopUpPayload = {
  amount: number;
  successUrl: string;
  language?: string;
  paymentProvider?: PaymentProvider;
};

export interface ApiLimits {
  login: string;
  areaLimit: number;
  aoiAreaLimit: number;
  processedArea: number;
}

export interface BillingRecord {
  archived: string;
  area: number;
  created: string;
  completionDate: string;
  cost: string;
  id?: string;
  email: string;
  name: string;
  projectName: string;
  dataProvider: string;
}

export type TopUpUrl = { paymentUrl: string };

export enum Role {
  owner = "owner",
  maintainer = "maintainer",
  contributor = "contributor",
  readOnly = "readonly",
}

export interface ShareProjectPayload {
  email?: string;
  role: Role;
  projectId: string;
  userId?: string;
}

export enum TeamRoles {
  OWNER = "OWNER",
  MEMBER = "MEMBER",
}
type Price = {
  zoom: number;
  priceSqKm: number;
};

type Provider = {
  id: string;
  name: string;
  displayName: string;
  previewUrl: string | null;
  webPreviewUrl: string | null;
  price: Price[];
  sourceType: string;
  description?: string | undefined;
};

export type Team = {
  activeUntil: string | null;
  creditsLimit: number;
  name: string;
  role: TeamRoles;
  teamId: string;
};

export type TeamStatus = {
  email: string;
  /**
   * in square meters
   */
  areaLimit: number;
  balance: number;
  /**
   * in bytes
   */
  memoryLimit: number;
  models: Model[];
  billingType: BillingType;
  remainingArea: number;
  remainingCredits: number;
  dataProviders: Provider[];
  teams: Team[];
  isAdmin: boolean;
};

export type TeamMember = {
  activeUntil: string | null;
  /**
   * in square meters
   */
  areaLimit: number;
  creditsLeft: number;
  creditsLimit: number;
  creditsUsed: number;
  email: string;
  /**
   * in square meters
   */
  processedArea: number;
  /**
   * in square meters
   */
  remainingArea: number;
  role: TeamRoles;
  userId: string;
};
